// extracted by mini-css-extract-plugin
export var bgImage = "heroVer-module--bgImage--qMw2f";
export var bgImgContainer = "heroVer-module--bgImgContainer--vJdCP";
export var bgImgContainerHide = "heroVer-module--bgImgContainerHide--7OZrk";
export var bottomText = "heroVer-module--bottomText--DJ3Z3";
export var bottomTextContainer = "heroVer-module--bottomTextContainer--ZDZiN";
export var box = "heroVer-module--box--tic8z";
export var container = "heroVer-module--container--x4v87";
export var content = "heroVer-module--content---07np";
export var contentHide = "heroVer-module--contentHide--yTXeS";
export var dotTyping = "heroVer-module--dotTyping--Dp1eS";
export var headings = "heroVer-module--headings--yB5Bq";
export var loading = "heroVer-module--loading--iPYvR";
export var magic = "heroVer-module--magic--1bp2L";
export var main = "heroVer-module--main---jC80";
export var mainHeading1 = "heroVer-module--mainHeading1--CGar-";
export var mainHeading2 = "heroVer-module--mainHeading2--oOzsk";
export var mainHeading3 = "heroVer-module--mainHeading3--U37cH";
export var mblBody = "heroVer-module--mblBody--OayeX";
export var mblBtmHeading = "heroVer-module--mblBtmHeading--4UguI";
export var mblDownArrow = "heroVer-module--mblDownArrow--hALij";
export var mblHeadingsContainer = "heroVer-module--mblHeadingsContainer--v84Nq";
export var mblMidHeading = "heroVer-module--mblMidHeading--mCs7Z";
export var mblPlayIcon = "heroVer-module--mblPlayIcon--IQtpN";
export var mblScrollText = "heroVer-module--mblScrollText--AKbZx";
export var mblScrollTxtCont = "heroVer-module--mblScrollTxtCont--gRom2";
export var mblTopHeading = "heroVer-module--mblTopHeading--1RPCP";
export var mblVideo = "heroVer-module--mblVideo--LAgj-";
export var mblVideoBody = "heroVer-module--mblVideoBody--Kg2F3";
export var mblVideoBox = "heroVer-module--mblVideoBox--MlGLM";
export var mblVideoCloseIcon = "heroVer-module--mblVideoCloseIcon--ELzHS";
export var mblVideoContainer = "heroVer-module--mblVideoContainer--HyUhX";
export var midContainer = "heroVer-module--midContainer--M9902";
export var playIcon = "heroVer-module--playIcon--5Cj-X";
export var playIconContainer = "heroVer-module--playIconContainer--dGnVc";
export var rightArrow = "heroVer-module--rightArrow--4+Epl";
export var scale = "heroVer-module--scale--phKq0";
export var scaleDown = "heroVer-module--scaleDown--9pQgP";
export var video = "heroVer-module--video--MZYn4";
export var videoBody = "heroVer-module--videoBody--lvf2e";
export var videoBodyHide = "heroVer-module--videoBodyHide--jzqcv";
export var videoBox = "heroVer-module--videoBox--fpZnu";
export var videoCloseIcon = "heroVer-module--videoCloseIcon--yI1dX";
export var videoContainer = "heroVer-module--videoContainer--I62em";