// extracted by mini-css-extract-plugin
export var dataContainer = "reviewContainer-module--dataContainer--qCoJW";
export var lowerText = "reviewContainer-module--lowerText---qfAM";
export var main = "reviewContainer-module--main--xWXDz";
export var mainImg = "reviewContainer-module--mainImg--fEOKz";
export var recDesc = "reviewContainer-module--recDesc--+IXCc";
export var revCont = "reviewContainer-module--revCont--f0IAB";
export var revText = "reviewContainer-module--revText--Z4gOQ";
export var slider = "reviewContainer-module--slider--eC4nF";
export var sliderContainer = "reviewContainer-module--sliderContainer--EBh-M";
export var starIcon = "reviewContainer-module--starIcon--iQJ+Y";