// extracted by mini-css-extract-plugin
export var bgContainer = "process-module--bgContainer--eGtVQ";
export var bottomRow = "process-module--bottomRow--g5bHL";
export var boxDetails = "process-module--boxDetails--ybgmR";
export var boxDetailsHide = "process-module--boxDetailsHide--1ctg0";
export var centerContainer = "process-module--centerContainer--q7XXo";
export var close = "process-module--close--Dgubo";
export var closeIcon = "process-module--closeIcon--XeLVf";
export var closeIconContainer = "process-module--closeIconContainer--4gmVr";
export var closeIconContainerBlur = "process-module--closeIconContainerBlur--Jnsrb";
export var closeIconContainerBlurRight = "process-module--closeIconContainerBlurRight--bnI9s";
export var closeIconContainerRight = "process-module--closeIconContainerRight--NpX17";
export var closeRow1 = "process-module--closeRow1--IeA8m";
export var closeRow2 = "process-module--closeRow2--l3N7-";
export var closeRow3 = "process-module--closeRow3--QFmbo";
export var closeRow4 = "process-module--closeRow4--QnCdE";
export var closeRow5 = "process-module--closeRow5--A087D";
export var dataContainer = "process-module--dataContainer--OgN7z";
export var heading = "process-module--heading--jO3Ou";
export var imageContainer1 = "process-module--imageContainer1--5HB9B";
export var imageContainer1Active = "process-module--imageContainer1Active--hru0Q";
export var imageContainer1Blur = "process-module--imageContainer1Blur--9gFB4";
export var imageContainer2 = "process-module--imageContainer2--cA3Hi";
export var imageContainer2Active = "process-module--imageContainer2Active--Rdwi1";
export var imageContainer2Blur = "process-module--imageContainer2Blur--t8TLU";
export var imageContainer3 = "process-module--imageContainer3--Qj28f";
export var imageContainer3Active = "process-module--imageContainer3Active--3bt4v";
export var imageContainer3Blur = "process-module--imageContainer3Blur--eoHp9";
export var imageContainer4 = "process-module--imageContainer4--zB+NZ";
export var imageContainer4Active = "process-module--imageContainer4Active--gQkho";
export var imageContainer4Blur = "process-module--imageContainer4Blur--3FjQT";
export var imageContainer5 = "process-module--imageContainer5--Z7NTh";
export var imageContainer5Active = "process-module--imageContainer5Active--FojFK";
export var imageContainer5Blur = "process-module--imageContainer5Blur--UX5wI";
export var imageText = "process-module--imageText--8Xi8E";
export var imgItem = "process-module--imgItem--eIGid";
export var main = "process-module--main--kew83";
export var mblDescription = "process-module--mblDescription--52XXO";
export var mblHeading = "process-module--mblHeading--qGoCq";
export var mblIcon = "process-module--mblIcon---LFlf";
export var mblMainContainer = "process-module--mblMainContainer--GGkto";
export var mblTitle = "process-module--mblTitle--tfTXf";
export var midRow = "process-module--midRow--0xN2g";
export var rightClose = "process-module--rightClose--DE0q6";
export var rightCloseIcon = "process-module--rightCloseIcon--VCNAH";
export var rightCloseRow1 = "process-module--rightCloseRow1--H2jG3";
export var rightCloseRow2 = "process-module--rightCloseRow2--lz6sM";
export var rightCloseRow3 = "process-module--rightCloseRow3--ND1I6";
export var rightCloseRow4 = "process-module--rightCloseRow4--Plpw0";
export var rightCloseRow5 = "process-module--rightCloseRow5--3ohyv";
export var rightCloseRow6 = "process-module--rightCloseRow6--o0wK6";
export var rightCloseRow7 = "process-module--rightCloseRow7--mTb0B";
export var rowsContainer = "process-module--rowsContainer--Bl400";
export var topRow = "process-module--topRow--ikNF9";