// extracted by mini-css-extract-plugin
export var contentInVisible = "service-module--contentInVisible--rRUO+";
export var contentVisible = "service-module--contentVisible--+18Bn";
export var dotTyping = "service-module--dotTyping--EdAz4";
export var heading = "service-module--heading--oINr0";
export var imagesContainer = "service-module--imagesContainer--8omD-";
export var imgCont = "service-module--imgCont--7nuKH";
export var imgItem = "service-module--imgItem--VcVgZ";
export var imgText = "service-module--imgText--mqQnh";
export var imglowerText = "service-module--imglowerText--4by-C";
export var loading = "service-module--loading--Ble5B";
export var main = "service-module--main--GM8xB";
export var mblBgBoxes = "service-module--mblBgBoxes--2SxfD";
export var mblHeading = "service-module--mblHeading--nrLgR";
export var mblImgCont = "service-module--mblImgCont--1zCIu";
export var mblImgItem = "service-module--mblImgItem--Zjwjq";
export var mblImgText = "service-module--mblImgText--jO8Mv";
export var mblImglowerText = "service-module--mblImglowerText--MWBwF";
export var mblPlayIcon = "service-module--mblPlayIcon--AGTms";
export var mblTextContainer = "service-module--mblTextContainer--LAHq5";
export var mblVideo = "service-module--mblVideo--wAsKH";
export var mblVideoBody = "service-module--mblVideoBody--kIULg";
export var mblVideoBox = "service-module--mblVideoBox--4RSl2";
export var mblVideoCloseIcon = "service-module--mblVideoCloseIcon--qRzY6";
export var mblVideoContainer = "service-module--mblVideoContainer--1C8MR";
export var newStyle = "service-module--newStyle--a7kMa";
export var newStyle1 = "service-module--newStyle1--aawxv";
export var newStyle2 = "service-module--newStyle2--WEZaI";
export var playIcon = "service-module--playIcon--jw5X6";
export var scale = "service-module--scale--YV7Xm";
export var scaleDown = "service-module--scaleDown--eTqCH";
export var video = "service-module--video--dxkgt";
export var videoBody = "service-module--videoBody--QjmvO";
export var videoBodyHide = "service-module--videoBodyHide--+3y5j";
export var videoBox = "service-module--videoBox--95My-";
export var videoCloseIcon = "service-module--videoCloseIcon--ABVru";
export var videoContainer = "service-module--videoContainer--FsS+J";