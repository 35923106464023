// extracted by mini-css-extract-plugin
export var bgImgBtm = "stories-module--bgImgBtm--66w+6";
export var bgImgBtmGlr = "stories-module--bgImgBtmGlr--MSG4V";
export var bgImgBtmGr = "stories-module--bgImgBtmGr--OEmB8";
export var bgImgBtmPlr = "stories-module--bgImgBtmPlr--gsKns";
export var bgImgBtmRed = "stories-module--bgImgBtmRed--30bj-";
export var bgImgLarge = "stories-module--bgImgLarge--3RemV";
export var bgImgLeft = "stories-module--bgImgLeft--x3D7R";
export var bgImgLeftContainer = "stories-module--bgImgLeftContainer--dIYYL";
export var bgImgRight = "stories-module--bgImgRight--veydU";
export var bottomContainer = "stories-module--bottomContainer--GGrVY";
export var box = "stories-module--box--x4U8+";
export var boxesContainer = "stories-module--boxesContainer--e51k7";
export var btmRowInSlider = "stories-module--btmRowInSlider--0qDTk";
export var btn = "stories-module--btn--xL4vJ";
export var crossesContainer = "stories-module--crossesContainer--qmhM5";
export var customDotsContainer = "stories-module--customDotsContainer--B3LSy";
export var description = "stories-module--description--VMv8I";
export var heading = "stories-module--heading--35Nrc";
export var icon = "stories-module--icon--X4GPW";
export var imageCover = "stories-module--imageCover--ftd7M";
export var imagesContainer = "stories-module--imagesContainer--+Hwvm";
export var imgTop = "stories-module--imgTop--z9msr";
export var main = "stories-module--main--I+Ocr";
export var mblBtnContainer = "stories-module--mblBtnContainer--dWEO+";
export var mblBtnText = "stories-module--mblBtnText--czF-E";
export var mblDescription = "stories-module--mblDescription--hwcqF";
export var mblHeading = "stories-module--mblHeading--1dMxI";
export var mblIcon = "stories-module--mblIcon--e3ooK";
export var mblImagesContainer = "stories-module--mblImagesContainer--8ODwJ";
export var mblImgTop = "stories-module--mblImgTop--1dtE2";
export var mblMain = "stories-module--mblMain--ONv-c";
export var mblSingleImgContainer = "stories-module--mblSingleImgContainer--0atZx";
export var mblSingleImgContainerActive = "stories-module--mblSingleImgContainerActive--JiKIu";
export var mblTitle = "stories-module--mblTitle--YoNV1";
export var mblTxtContainer = "stories-module--mblTxtContainer--zJwhv";
export var rightBoxContainer = "stories-module--rightBoxContainer--ffDiO";
export var singleImgContainer = "stories-module--singleImgContainer--9TKnE";
export var singleImgContainerBlur = "stories-module--singleImgContainerBlur--c2QAZ";
export var slider = "stories-module--slider--+gp9z";
export var title = "stories-module--title--vMoVh";
export var topContainer = "stories-module--topContainer--cNZy2";
export var txtContainer = "stories-module--txtContainer--0w-vc";
export var upperContainer = "stories-module--upperContainer--Du7Vi";