// extracted by mini-css-extract-plugin
export var bgBoxes = "aboutUs-module--bgBoxes--YU-NL";
export var bgBoxesHide = "aboutUs-module--bgBoxesHide--yxmiz";
export var bgCross = "aboutUs-module--bgCross--NJHIo";
export var body = "aboutUs-module--body--V4dZ-";
export var boxesContainer = "aboutUs-module--boxesContainer--Nm+ky";
export var boxesImage = "aboutUs-module--boxesImage--vvYHB";
export var button = "aboutUs-module--button--OvF8g";
export var buttonTitle = "aboutUs-module--buttonTitle--CvuMA";
export var containerRow1 = "aboutUs-module--containerRow1--ewFEd";
export var containerRow2 = "aboutUs-module--containerRow2--ZN3TT";
export var containerRow3 = "aboutUs-module--containerRow3--Zcgf+";
export var containerRow4 = "aboutUs-module--containerRow4--Li4qi";
export var containerRow5 = "aboutUs-module--containerRow5--vZ9e0";
export var containerRow6 = "aboutUs-module--containerRow6--Mhoa7";
export var firstBox = "aboutUs-module--firstBox--q6+qG";
export var floating = "aboutUs-module--floating--+p1rU";
export var lrgBox = "aboutUs-module--lrgBox--vEOlH";
export var main = "aboutUs-module--main--KSrMc";
export var mblBgCross = "aboutUs-module--mblBgCross--2MHQ2";
export var mblMain = "aboutUs-module--mblMain--gMI2V";
export var mblParagraph = "aboutUs-module--mblParagraph--cXgtU";
export var mblTextContainer = "aboutUs-module--mblTextContainer--6qnwj";
export var mblTitle = "aboutUs-module--mblTitle---Vj3f";
export var mdmBox = "aboutUs-module--mdmBox--lYbBK";
export var mymove = "aboutUs-module--mymove--wGX6N";
export var paragraph = "aboutUs-module--paragraph--DuGzH";
export var smallBox = "aboutUs-module--smallBox--rMm1v";
export var title = "aboutUs-module--title--1zwFz";
export var titleContainer = "aboutUs-module--titleContainer--bxo7D";